import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "endsAt", "current" ]

  connect() {
    this.endsAtTarget.hidden = this.currentTarget.checked
  }

  setDisableInput({ target }) {
    this.endsAtTarget.hidden = target.checked
  }
}
