import { Controller } from "stimulus"

// Usage:
//
// <div data-controller="countdown-chars">
//   <span data-target="countdown-chars.output"></span>
//
//   <input maxlength="140" data-target="countdown-chars.input" data-action="input->countdown-chars#update">
// </div>

export default class extends Controller {
  static targets = [ "input", "output" ]

  connect() {
    this.maxLength = this.inputTarget.maxLength
    this.outputTarget.innerText = this.maxLength
  }

  update({ target }) {
    this.outputTarget.innerText = this.maxLength - target.value.length
  }
}
