import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message" ]

  connect() {
    this.messageTarget.hidden = false

    setTimeout(() => {
      this.messageTarget.hidden = true
    }, 4000);
  }
}
