import { Controller } from "stimulus"

// Usage
//
// <div data-controller="tabs">
//   <div data-target="tabs.links">
//     <a class="tab tab-active" data-action="click-> tabs#switch" data-tab-id="course">
//       Curso
//     </a>
//     <a class="tab tab-default" data-action="click-> tabs#switch" data-tab-id="annotations">
//       Apuntes
//     </a>
//   </div>
//
//   <div data-target="tabs.contents">
//     <div data-tab-content-id="course">
//       Course
//     </div>
//     <div class="hidden" data-tab-content-id="annotations">
//       Annotations
//     </div>
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "links", "contents" ]

  connect() {
  }

  switch(event){
    const selectedTab = this._selectedTab(event);
    const tabId = selectedTab.dataset.tabId

    if (!selectedTab.classList.contains("tab-active")) {
      // Tabs links
      this.linksTarget.querySelectorAll("a").forEach((tabLink) => {
        tabLink.classList.remove("tab-active");
        tabLink.classList.add("tab-default")
      });

      selectedTab.classList.remove("tab-default");
      selectedTab.classList.add("tab-active");

      // Tabs contents
      this.contentsTarget.querySelectorAll("[data-tab-content-id]").forEach((tabContent) => {
        tabContent.classList.add('hidden');
      });

      this.contentsTarget.querySelector(`[data-tab-content-id='${tabId}']`).classList.remove('hidden');
    }
  }

  // private

  _selectedTab(event){
    const target = event.target;

    if (target.hasAttribute("data-tab-id")) {
      return target;
    } else {
      return target.closest("[data-tab-id]")
    }
  }
}
