import { Controller } from "stimulus"

// Usage:
//
// - Main link:
//  <a href="#" data-action="mouseenter->fader#show mouseleave->fader#hide">...</a>
// - Content to fadeIn/fadeOut:
//  <div data-target="fader.content" data-action="mouseenter->fader#keep mouseleave->fader#hide">...</div>

// - "open" class will determine if the content should open. It will be added when the mouse enters,
//    but the open funciton has a timeout. If the mouse leaves, the class will be removed and the open
//    function won't do anything without it.
// - "keep" class will avoid the content to be close. It's used when moving the mouse from the link to the content.

export default class extends Controller {
  static targets = [ "content" ]

  connect() {
  }

  show(event) {
    this.contentTarget.classList.remove("-z-10")
    this.contentTarget.classList.add("open");
    setTimeout(() => {
      this._showContent()
    }, 250);
  }

  hide(event) {
    event.currentTarget.classList.add('active-dropdown');
    this.contentTarget.classList.remove("open", "keep");
    const timerHideContent = event.currentTarget.classList.contains('dropdown-element') ? 50 : 250
    setTimeout(() => {
      this._hideContent()
    }, timerHideContent);
  }

  keep(event) {
    this.contentTarget.classList.add("open", "fadeIn");

    const outline = event.target;
    const style = window.getComputedStyle(outline);

    if (style.opacity === "1") {
      this._showContent()
    }
  }

  // private
  _showContent(){
    if (this.contentTarget.classList.contains('open')) {
      this.contentTarget.classList.remove("fadeOut")
      this.contentTarget.classList.add("z-30", "fadeIn", "keep")
    }
  }

  _hideContent(){
    if (!this.contentTarget.classList.contains('keep') && !this.contentTarget.classList.contains('open')) {
      this._activeElements.forEach(element => element.classList.remove('active-dropdown'));
      this.contentTarget.classList.remove("fadeIn")
      this.contentTarget.classList.add("fadeOut")
      setTimeout(() => {
        this.contentTarget.classList.remove("z-30")
        this.contentTarget.classList.add("-z-10")
      }, 250);
    }
  }

  //Get all elements with the class 'active-dropdown'
  get _activeElements() {
    return document.querySelectorAll('.active-dropdown');
  }
}
