import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"

export default class extends Controller {
  connect() {
  }

  reply(event){
    const replyLinkElement = event.target;
    const targetId = replyLinkElement.dataset.replyTargetId;
    const targetForm = document.querySelector(`[data-form-target-id="${targetId}"]`);

    targetForm.classList.remove('hidden');
    scrollTo(targetForm);
  }
}
