import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "message", "saveButton" ]

  connect() {
  }

  beforeSend(xhr) {
    if (document.querySelector("#question_title").value === '' || document.querySelector("trix-editor").value === '') {
      this.messageTarget.innerHTML = 'Debe rellenar todos los campos';
      this.messageTarget.classList.remove('hidden');
      xhr.preventDefault();
    } else {
      this.messageTarget.innerHTML = 'Guardando...';
      this.messageTarget.classList.remove('hidden');

      this.saveButtonTarget.disabled = true;
    }
  }
}
