import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"

export default class extends Controller {
  connect() {
  }

  maybeForceCompleteQuiz(event) {
    if (event.target.classList.contains("js-force-complete-quiz")) {
      event.preventDefault();

      const warningDiv = document.querySelector(".js-complete-quiz-warning");
      warningDiv.classList.remove('hidden');
      scrollTo(warningDiv);
    }
  }
}
