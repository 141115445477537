import { Controller } from "stimulus"

// NOTE: this controller can be replaced with details-summary HTML tags
// https://www.w3schools.com/TAGS/tag_details.asp
export default class extends Controller {

  static targets = [ 'items', 'arrow' ]

  toggle(event) {
    event.preventDefault()

    const section = event.currentTarget.dataset.section
    const items = this.itemsTargets.filter(item => item.dataset.sectionItems === `${section} items`)
    const arrows = this.arrowTargets.filter(arrow => arrow.dataset.sectionArrow === `${section} arrow`)

    items.forEach(item => item.classList.toggle("hidden"))
    arrows.forEach(arrow => arrow.classList.toggle("hidden"))
  }
}
