import { Controller } from "stimulus"
import { urlParams } from "lib/utils"

export default class extends Controller {
  static targets = [ "highlightable" ]

  connect() {
    if (this._hasValidParams()) {
      this._highlight();
    }
  }

  // private
  _highlight(){
    const text = urlParams('hltext');
    const regex = new RegExp(text ,"gi");;
    const currentValue = this.highlightableTarget.innerHTML;
    const highlightedValue = currentValue.replace(regex, `<span class="highlighted">$&</span>`);

    this.highlightableTarget.innerHTML = highlightedValue;
  }

  _hasValidParams() {
    const params = urlParams();

    return urlParams('hltext') &&
      urlParams('hlid') &&
      urlParams('hlid') == this.highlightableTarget.dataset.highlighterId;
  }
}
