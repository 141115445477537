import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"
import Sortable from 'sortablejs';
import axios from "axios";
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "container", "startSortingLink" ]

  connect() {
    this._initSortables();
  }

  toggleSorting(event) {
    event.preventDefault();

    if (this._isSorting()) {
      this._endSorting();
    } else {
      this._startSorting();
    }
  }

  hideAnnotation(event){
    event.preventDefault();

    const link = event.target.parentElement
    const savedAnnotationId = link.dataset.savedAnnotationId;
    const requestUrl = link.dataset.requestUrl;

    const requestData = {
      saved_annotation: {
        id: savedAnnotationId
      }
    }

    const requestConfig = {
      headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
    }

    axios.post(requestUrl, requestData, requestConfig)
    .then((response) => {
      this._onSuccessAnnotationHide(savedAnnotationId);
    })
    .catch((error) => {
      this._onErrorAnnotationHide(error);
    })
  }

  unhideAnnotation(event){
    event.preventDefault();

    const link = event.target.parentElement
    const savedAnnotationId = link.dataset.savedAnnotationId;
    const requestUrl = link.dataset.requestUrl;

    const requestData = {
      saved_annotation: {
        id: savedAnnotationId
      }
    }

    const requestConfig = {
      headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
    }

    axios.post(requestUrl, requestData, requestConfig)
    .then((response) => {
      this._onSuccessAnnotationUnhide(savedAnnotationId);
    })
    .catch((error) => {
      this._onErrorAnnotationHide(error);
    })
  }

  toggleHiddenControls(event){
    event.preventDefault();

    const elements = event.target.parentElement.children
    const controlsElement = Array.prototype.find.call(elements, (ele) => {
      return ele.classList.contains('js-hidden-annotations')
    })

    controlsElement.classList.toggle('hidden');
  }

  // private
  _startSorting(){
    this.startSortingLinkTarget.innerHTML = 'Terminar edición'
    this.containerTarget.classList.add("reorder-annotations");
    this._enableSortables();
    Array.from(document.querySelectorAll(".js-annotation-hidden-controls-main")).forEach((element) => {
      element.classList.remove('hidden');
    })
  }

  _endSorting(){
    this.startSortingLinkTarget.innerHTML = 'Reordenar apuntes'
    this.containerTarget.classList.remove("reorder-annotations")
    this._disableSortables();
    Array.from(document.querySelectorAll(".js-annotation-hidden-controls-main")).forEach((element) => {
      element.classList.add('hidden');
    })
  }

  _isSorting(){
    return this.containerTarget.classList.contains("reorder-annotations");
  }

  _onSuccessAnnotationHide(savedAnnotationId){
    document.querySelector(`.js-visible-annotation[data-saved-annotation-id="${savedAnnotationId}"]`).classList.add('hidden');
    document.querySelector(`.js-hidden-annotation[data-saved-annotation-id="${savedAnnotationId}"]`).classList.remove('hidden');
    this._updateCounter(savedAnnotationId);
  }

  _onSuccessAnnotationUnhide(savedAnnotationId){
    document.querySelector(`.js-visible-annotation[data-saved-annotation-id="${savedAnnotationId}"]`).classList.remove('hidden');
    document.querySelector(`.js-hidden-annotation[data-saved-annotation-id="${savedAnnotationId}"]`).classList.add('hidden');
    this._updateCounter(savedAnnotationId);
  }

  _updateCounter(savedAnnotationId){
    const annotation = document.querySelector(`.js-hidden-annotation[data-saved-annotation-id="${savedAnnotationId}"]`)
    const mainBlock = annotation.closest(".js-annotation-hidden-controls-main");
    const link = mainBlock.querySelector(".js-counter")

    const count = mainBlock.querySelectorAll("div.js-hidden-annotation:not(.hidden)").length
    const newLinkText = count === 1 ? "1 apunte oculto" : `${count} apuntes ocultos`

    link.innerHTML = newLinkText;
  }

  _onErrorAnnotationHide(error){
    console.log(error);
  }

  _initSortables(){
    const annotationsContainers = document.querySelectorAll('.js-pill-annotations');

    annotationsContainers.forEach((container) => {
      const pillId = container.dataset.pillId;

      new Sortable(container, {
        group: `pill-${container.dataset.pillId}`,
        dragClass: "js-draggable",
        disabled: true,
        onEnd: function(event){
          document.querySelector('.js-loading').classList.remove('hidden');
          document.querySelector('.js-saved').classList.add('hidden');
          const form = this.el;

          // Set the hidden fields values
          const positionInputs = document.querySelectorAll("[data-pill-id='"+form.dataset.pillId+"'][data-field='position']")

          Array.from(positionInputs).forEach((input, index) => {
            input.value = index;
          });

          Rails.fire(form, 'submit');

          const draggedItem = event.item;
          draggedItem.classList.add("just-reordered");
          setTimeout(()=>{
            draggedItem.classList.add("reordered");
          }, 200);
          setTimeout(()=>{
            draggedItem.classList.remove("just-reordered");
            draggedItem.classList.remove("reordered");
          }, 600);
        }
      });
    })
  }

  _enableSortables(){
    this._getSortables().forEach((sortable) => {
      sortable.option("disabled", false)
    });
  }

  _disableSortables(){
    this._getSortables().forEach((sortable) => {
      sortable.option("disabled", true)
    });
  }

  _getSortables(){
    const annotationsContainers = document.querySelectorAll('.js-pill-annotations');

    return Array.from(annotationsContainers).map((container) => {
      return Sortable.get(container)
    })

  }
}
