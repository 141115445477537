import { Controller } from "stimulus"
import * as CookieConsent from 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

export default class extends Controller {
  connect() {
    CookieConsent.run({

    // root: 'body',
    // autoShow: true,
    // disablePageInteraction: true,
    // hideFromBots: true,
    // mode: 'opt-in',
    // revision: 0,

    cookie: {
        name: 'cc_cookie',
        // domain: location.hostname,
        // path: '/',
        // sameSite: "Lax",
        // expiresAfterDays: 365,
    },

    // https://cookieconsent.orestbida.com/reference/configuration-reference.html#guioptions
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom center',
            equalWeightButtons: false,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },

    onFirstConsent: ({cookie}) => {
        console.log('onFirstConsent fired',cookie);
    },

    onConsent: ({cookie}) => {
        console.log('onConsent fired!', cookie)
    },

    onChange: ({changedCategories, changedServices}) => {
        console.log('onChange fired!', changedCategories, changedServices);
    },

    onModalReady: ({modalName}) => {
        console.log('ready:', modalName);
    },

    onModalShow: ({modalName}) => {
        console.log('visible:', modalName);
    },

    onModalHide: ({modalName}) => {
        console.log('hidden:', modalName);
    },

    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
            },
        analytics: {
            enabled: false,  // this category is enabled by default
            readOnly: false,  // this category cannot be disabled
            autoClear: {
                cookies: [
                    {
                        name: '__zlcmid',   // string: exact cookie name
                    },
                    {
                        name: '__kla_id',   // string: exact cookie name
                    },
                ]
              },
            }
        },

    language: {
        default: 'es',
        translations: {
            es: {
                consentModal: {
                    title: 'Usamos Cookies',
                    description: 'En Ingenio usamos cookies para para gestionar tu sesión, entender cómo usas la plataforma y mejorar nuestro servicio. <button type="button" data-cc="show-preferencesModal" style="font-weight:700; text-decoration: underline; display: inline-block">Déjame elegir.</button>',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    // showPreferencesBtn: 'Gestionar preferencias',
                    // closeIconLabel: 'Reject all and close modal',
                    // footer: `
                    //     <a href="/cookies" target="_blank">Politica de cookies</a>
                    //     <a href="https://ten.ingenioxyz.com/p/privacy" target="_blank">Política de privacidad</a>
                    // `,
                },
                preferencesModal: {
                    title: '🍪 Gestiona tus preferencias de cookies',
                    acceptAllBtn: 'Aceptar todas',
                    acceptNecessaryBtn: 'Rechazar todas',
                    savePreferencesBtn: 'Aceptar selección actual',
                    closeIconLabel: 'Cerrar',
                    serviceCounterLabel: 'Servicio|Servicios',
                    sections: [
                        {
                            title: 'Uso de cookies',
                            description: 'En Ingenio usamos cookies para para gestionar tu sesión, entender cómo usas la plataforma y mejorar nuestro servicio. Se dividen en dos categorías:'
                        },
                        {
                            title: 'Cookies técnicas (necesarias)',
                            description: 'Sin ellas el sitio no puede funcionar. Sin ellas el site no puede funcionar. Fundamentalmente se usan para poder gestionar tu sesión.',

                            //this field will generate a toggle linked to the 'necessary' category
                            linkedCategory: 'necessary',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                    desc: 'Descripción'
                                },
                                body: [
                                    {
                                        name: '_ingenio_session',
                                        domain: location.hostname,
                                        desc: 'Gestión de usuarios logueados.',
                                    },
                                    {
                                        name: '_ajs_user_id',
                                        domain: location.hostname,
                                        desc: 'Gestión de usuarios logueados.',
                                    },
                                    {
                                        name: 'ingenio-ref-login',
                                        domain: location.hostname,
                                        desc: 'Permite iniciar sesión.',
                                    },
                                    {
                                        name: 'ingenio-ref-signup',
                                        domain: location.hostname,
                                        desc: 'Permite el registro de nuevos usuarios.',
                                    },
                                    {
                                        name: 'ahoy_visit',
                                        domain: location.hostname,
                                        desc: 'Análisis y mejora del servicio.',
                                    },
                                    {
                                        name: 'ahoy_visitors',
                                        domain: location.hostname,
                                        desc: 'Análisis y mejora del servicio.',
                                    },
                                ]
                            }
                        },
                        {
                            title: 'Cookies de analítica',
                            description: 'Utilizamos servicios para analizar tu actividad, darte soporte y ofrecerte productos y servicios relacionados.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: 'Cookie',
                                    domain: 'Dominio',
                                    duration: 'Expira',
                                    desc: 'Descripción'
                                },
                                body: [
                                    {
                                        name: '__zlcmid',
                                        domain: 'zopim.com',
                                        duration: 'Persistente',
                                        desc: 'Servicio de Chat persistente entre páginas.',
                                    },
                                    {
                                        name: '__kla_id',
                                        domain: 'klaviyo.com',
                                        duration: '2 años',
                                        desc: 'Servicio de email marketing',
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Más información',
                            description: 'Para más detalles visita nuestra <a href="/cookies" target="_blank">política de cookies.</a>'
                        },
                    ]
                }
            }
        }
    }
});

  }
}
