import { Controller } from "stimulus"

// Usage:
//
// <div data-controller="view-more"
//      data-view-more-show="texto botón para mostrar"
//      data-view-more-hide="texto botón para ocultar">
//   <div data-target="view-more.item">
//     ...
//   </div>
//   <div data-target="view-more.item" hidden>
//     ...
//   </div>
//
//   <button data-action="click->view-more#toggle"
//           data-target="view-more.button">
//     Click
//   </button>
//
// </div>

export default class extends Controller {
  static targets = [ "button", "item" ]

  connect() {
    this.showText = this.data.get("show")
    this.hideText = this.data.get("hide") || this.showText

    // Amount of visible items
    this.VISIBLE_ITEMS = this.itemTargets.filter(x => !x.hidden).length

    if (this.hasButtonTarget) {
      this.buttonTarget.innerText = this.showText
    }
  }

  toggle(){
    this.itemTargets.forEach((item, ix) => {
      // From this index on, the items are hidden o shown
      if (ix >= this.VISIBLE_ITEMS) {
        item.hidden = !item.hidden;
      }
    });

    // switch the button text
    this.buttonTarget.innerText = this.buttonTarget.innerText === this.hideText ? this.showText : this.hideText
  }
}
