import { Controller } from "stimulus"

// Usage
//
// <div data-controller="filter">
//   <div data-target="filter.links">
//     <a class="active" data-action="click-> filter#switch" data-filter-id="filter_1">
//       Filtro 1
//     </a>
//     <a class="" data-action="click-> filter#switch" data-filter-id="annotationfilter_2">
//       Filtro 2
//     </a>
//   </div>
//
//   <div data-target="filter.contents">
//     <div data-filter-names="filter_1">
//       Item A
//     </div>
//     <div data-filter-names="filter_2">
//       Item B
//     </div>
//     <div data-filter-names="filter_1 filter_2">
//       Item C
//     </div>
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "links", "contents" ]

  connect() {
    if (this.hasLinksTarget){
      const initialSelectedLink = this.linksTarget.querySelector("a.active");
      const filterId = initialSelectedLink.dataset.filterId

      this._showRelevantContents(filterId);
    }
  }

  filter(event){
    event.preventDefault();

    const selectedLink = event.target;
    const filterId = selectedLink.dataset.filterId

    this._markSelectedLink(selectedLink);
    this._showRelevantContents(filterId);
  }

  // private
  _markSelectedLink(selectedLink){
    this.linksTarget.querySelectorAll("a").forEach((tabLink) => {
      tabLink.classList.remove("active");
    });

    selectedLink.classList.add('active');
  }

  _showRelevantContents(filterId){
    this.contentsTarget.querySelectorAll("[data-filter-names]").forEach((item) => {
      const itemFilters = item.dataset.filterNames.split(' ');

      if (itemFilters.includes(filterId)) {
        item.classList.remove('hidden');
      } else {
        item.classList.add('hidden');
      }
    });
  }
}
