import { Controller } from "stimulus"
import TomSelect from "tom-select"
import "tom-select/dist/css/tom-select.css"

// Usage
//
// <div data-controller="tom-select">
//   <select data-plugins="['dropdown_input']" data-create="true" ...>
//     ...
//   </select>
// </div>
export default class extends Controller {
  connect () {
    this.element
      .querySelectorAll("select")
      .forEach(
        (select) =>
          new TomSelect(
            select,
            {
              // Opciones por defecto para todos los selectores
              render: {
                ...select.dataset?.render,
                option_create: function(data, escape) {
                  return `<div class="create">Añade <strong>${escape(data.input)}</strong>&hellip;</div>`;
                },
                no_results:function(data,escape){
                  return `<div class="no-results">No se han encontrado resultados para "${escape(data.input)}"</div>`;
                },
              },
              // Opciones exclusivas de cada selector, definidas en el HTML
              ...Object.fromEntries(Object.entries({ ...select.dataset }).map(([k, v]) => [k, JSON.parse(v)]))
            }
          )
      );
  }
}
