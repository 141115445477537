import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "iframe"]

  connect() {
  }

  showModal() {

    this._setScrollPosition()
    this._bodyElement.style['top'] = `${-this.scrollPosition}px`;
    this.modalTarget.style['top'] = `${this.scrollPosition}px`

    this._bodyElement.classList.add('overflow-y-scroll', 'fixed')
    this.modalTarget.classList.remove('hidden', 'fadeOut')
    this.modalTarget.classList.add('fadeIn')
    this.iframeTarget.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
  }

  closeModal() {
    this.iframeTarget.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');

    this._bodyElement.classList.remove('overflow-y-scroll', 'fixed')

    this.modalTarget.classList.remove('fadeIn')
    this.modalTarget.classList.add('fadeOut')

    window.scrollTo(0, this.scrollPosition)
    setTimeout(() => {
      this.modalTarget.classList.add('hidden')
    }, 300);
  }

  get _bodyElement() {
    return document.getElementsByTagName('body')[0];
  }

  _setScrollPosition() {
    this.scrollPosition = document.documentElement.scrollTop
  }
}
