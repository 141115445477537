import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"

export default class extends Controller {
  static targets = [ "annotationsLink" ]

  connect() {
  }

  moveToAnnotations(event) {
    event.preventDefault();

    this.annotationsLinkTarget.click();
    scrollTo(this.annotationsLinkTarget);
  }
}
