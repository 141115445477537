export function scrollTo(element) {
  const marginTop = 20;
  window.scroll({
    behavior: 'smooth',
    left: 0,
    top: element.offsetTop-marginTop
  });
}

export function urlParams(key) {
  const output = {};
  const url = window.location.href;
  const [host, params=''] = url.split('?');

  params.split('&').forEach((param) => {
    const [key, value] = param.split('=');
    output[key] = decodeURIComponent(value);
  })

  if (key) {
    return output[key];
  } else {
    return output;
  }
};
