import { Controller } from "stimulus"
import { scrollTo } from "lib/utils"
import axios from "axios";

export default class extends Controller {
  connect() {
  }

  select(event) {
    const currentQuizOption = event.target;
    const quizQuestionElement = this._currentQuizQuestion(currentQuizOption);
    const quizOptions = quizQuestionElement.querySelectorAll('.quiz-option');
    const checkButton = this._checkButtonFor(quizQuestionElement);

    if (checkButton.dataset.checked === 'false') {
      quizOptions.forEach((selection) => selection.classList.remove('selected'));
      currentQuizOption.classList.add('selected');

      checkButton.classList.remove('hidden');
    }
  }

  check(event){
    event.preventDefault();
    const checkButton = event.target

    if (checkButton.dataset.checked === 'false') {
      // if this quiz question are unlocked: never answered
      const quizQuestionElement = this._currentQuizQuestion(checkButton);
      const selectedQuizOption = quizQuestionElement.querySelector('.quiz-option.selected');
      const spinner = quizQuestionElement.querySelector(".js-check-spinner");
      const requestData = {
        participation: {
          course_id: this.element.dataset.courseId,
          teachable_user_id: this.element.dataset.teachableUserId,
          quiz_id: this.element.dataset.quizId,
          quiz_question_id: quizQuestionElement.dataset.quizQuestionId,
          quiz_answer_id: selectedQuizOption.dataset.quizAnswerId
        }
      }

      const requestConfig = {
        headers: { 'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content }
      }

      spinner.classList.remove('hidden');
      checkButton.classList.add('hidden');

      // check answer selected to backend
      axios.post('/participations', requestData, requestConfig)
      .then((response) => {
        // participation: quiz_answer sent
        this._onSuccessParticipationSave(event);
      })
      .catch((error) => {
        checkButton.classList.remove('hidden');
        this._onErrorParticipationSave(error);
      })
      .then(() => {
        spinner.classList.add('hidden');
      });
    }
  }

  continue(event){
    event.preventDefault();

    const continueButton = event.target;
    const quizQuestionElement = this._currentQuizQuestion(continueButton);

    const nextPillMeta = document.getElementById('js-next-pill-meta');

    if (quizQuestionElement.dataset.lastQuestion === 'true') {
      if(nextPillMeta?.dataset?.nextPill !== undefined){
        // user not logged or not enrolled, move to next public pill
        window.location.replace(nextPillMeta.dataset.nextPill);
      } else {
        // user enrolled, move to next pill
        this._completePill();
      }
    } else {
      this._showNextQuestion(quizQuestionElement);
    }
  }

  // private
  _onSuccessParticipationSave(event){
    const checkButton = event.target
    const quizQuestionElement = this._currentQuizQuestion(checkButton);
    const continueButton = this._continueButtonFor(quizQuestionElement);
    const selectedQuizOption = quizQuestionElement.querySelector('.quiz-option.selected');
    const correctQuizOption = quizQuestionElement.querySelector("[data-correct='true']");

    // Lock this quiz question to avoid changes
    checkButton.dataset.checked = "true";

    // Show explanation and next step button
    continueButton.classList.remove('hidden');
    quizQuestionElement.querySelector('.explanation').classList.remove('hidden');

    // Mark options
    correctQuizOption.appendChild(this._optionCorrectnessMarker(true));

    if (selectedQuizOption.dataset.correct === "true") {
      quizQuestionElement.querySelector("[data-correct-message='']").classList.remove('hidden');
    } else {
      selectedQuizOption.appendChild(this._optionCorrectnessMarker(false));
      selectedQuizOption.classList.add("quiz-option-fail");
      selectedQuizOption.classList.remove("selected");
      correctQuizOption.classList.add("selected");
      quizQuestionElement.querySelector("[data-incorrect-message='']").classList.remove('hidden');
    }
  }

  _onErrorParticipationSave(error){
    console.log(error);
  }

  _completePill(){
    const continueButton = document.querySelector(".js-complete-and-continue");

    if (continueButton.classList.contains('js-force-complete-quiz')) {
      continueButton.classList.remove('js-force-complete-quiz')
    }

    continueButton.click();
  }

  _showNextQuestion(currentQuestion){
    const currentIndex = parseInt(currentQuestion.dataset.quizQuestionIndex);
    const nextQuestion = document.querySelector(`.js-quiz-question[data-quiz-question-index='${currentIndex+1}']`);

    currentQuestion.classList.add("hidden");
    nextQuestion.classList.remove("hidden");

    // Update Question form
    this._updateQuestions(nextQuestion);

    // Scroll to next question
    const questionsDiv = document.getElementById('quiz-questions');
    scrollTo(questionsDiv);
  }

  _updateQuestions(quizQuestion){
    // Update questionable id in form
    if (nextPillMeta?.dataset?.nextPill !== undefined){
      const questionableIdInput = document.querySelector('input#question_questionable_id')
      questionableIdInput.value = quizQuestion.dataset.quizQuestionId;
    }

    // Update current questions
    const spinner = document.querySelector(".js-questions-spinner");
    document.querySelectorAll(".js-question").forEach((question) => question.remove())
    spinner.classList.remove('hidden')
    const url = `/questions/quiz/${quizQuestion.dataset.quizQuestionId}`;

    const nextPillMeta = document.getElementById('js-next-pill-meta');

    if(nextPillMeta?.dataset?.nextPill === undefined){
      // enrolled mode, need to save participation
      axios({method: "get", url, headers: { "Accept": "text/html"} })
      .then((response) => {
        this._onSuccessQuestionsRetrieval(response)
      })
      .catch((error) => {
        this._onErrorQuestionsRetrieval(error)
      })
      .then(() => {
        spinner.classList.add('hidden');
      });
    } else {
      // public mode, nothing to save
      spinner.classList.add('hidden');
    }
  }

  _onSuccessQuestionsRetrieval(response){
    document.querySelector('.js-questions-list').insertAdjacentHTML("beforeend", response.data);
  }

  _onErrorQuestionsRetrieval(error){
    console.log(error);
  }

  _currentQuizQuestion(element){
    return element.closest(".js-quiz-question");
  }

  _checkButtonFor(quizQuestion){
    return quizQuestion.querySelector(`button[data-button-id='check']`);
  }

  _continueButtonFor(quizQuestion){
    return quizQuestion.querySelector(`button[data-button-id='continue']`);
  }

  _optionCorrectnessMarker(isCorrect){
    const text = isCorrect ? '✔️' : '❌'
    const marker = document.createElement("p");
    marker.classList.add("float-right");
    marker.appendChild(document.createTextNode(text));

    return marker;
  }
}
