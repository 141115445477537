import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "communityLink", "sidebarLink", "paragraph" ]

  connect() {
    // Infinte loop if turbolinks is disabled
    // this.sidebarLinkTargets[0].click();
  }

  activeCategory(event) {
    event.preventDefault()

    //Get the category selected
    const getCategorySelected = event.target.getAttribute("data-toggle-category")

    //Removes active class of the category list
    this._categoryElements.forEach(element => {
      const { className } = element
      if (className.includes('active')) {
        element.classList.remove('active')
      }
    });

    //Hide paragraph when category
    if(getCategorySelected.endsWith("-special")){
      this.paragraphTarget.style.visibility = "hidden"
    } else {
      this.paragraphTarget.style.visibility = "visible"
    }

    //Add active class to the selected category
    event.target.classList.add('active')

    // Changes url and title of the selected category
    this.communityLinkTarget.innerHTML = `${event.target.dataset.communityName}`;
    this.communityLinkTarget.setAttribute('href', event.target.dataset.communityUrl);

    //Show the courses with the selected category and hide the rest
    this._coursesElements.forEach(element => {
      const categoryCourse = element.getAttribute('data-category').split(',')
      if(categoryCourse.includes(getCategorySelected)) {
        element.classList.remove('active')
        setTimeout(() => {
          element.classList.add('active')
        }, 50)
      } else {
        element.classList.remove('active')
      }
    });
  }

  //Get all elements from the category list
  get _categoryElements() {
    return this.element.querySelectorAll('[data-toggle-category]');
  }

  //Get all courses
  get _coursesElements() {
    return document.querySelectorAll('.course-card');
  }
}
