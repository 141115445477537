import { Controller } from "stimulus"

// Usage
//
// <div data-controller="toggle-visibility">
//   <button data-action="click->toggle-visibility#toggle">
//     Click
//   </button>
//   <div id="mobile-menu" data-target="toggle-visibility.hideable">
//     wadus
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "hideable" ]

  connect() {
  }

  toggle(event){
    const linkElement = event.target;

    if (this._isHideableVisible()) {
      this.hideableTarget.classList.add(this._cssClass());
      this._bodyElement.classList.remove('overflow-y-scroll', 'fixed')
    } else {
      this.hideableTarget.classList.remove(this._cssClass());
      this._bodyElement.classList.add('overflow-y-scroll', 'fixed')
    }
  }

  _isHideableVisible(){
    return !this.hideableTarget.classList.contains(this._cssClass());
  }

  _cssClass(){
    return(this.hideableTarget.dataset.hiddenClassValue ? this.hideableTarget.dataset.hiddenClassValue : 'hidden');
  }

  get _bodyElement() {
    return document.getElementsByTagName('body')[0];
  }
}
