import { Controller } from "stimulus"
import axios from "axios";

export default class extends Controller {
  connect() {
  }

  updateFrequency(event) {
    const tokenContainer = document.querySelector('meta[name="csrf-token"]')
    let requestConfig = {};

    if (tokenContainer) {
      requestConfig = { headers: { 'X-CSRF-Token': tokenContainer.content } }
    }

    const requestData = {
      frequency: event.target.dataset.frequencyValue,
      key: event.target.dataset.key,
      community_id: event.target.dataset.communityId
    }

    axios.post('/ajustes', requestData, requestConfig)
    .then((response) => {
      this._updateSaveStatusMessage(response.data.key , "Preferencia guardada correctamente");
    })
    .catch((error) => {
      // We can't know the notification group where it failed, so we just show it in the first one.
      // This only happens in http status 500.
      this._updateSaveStatusMessage('likes_notification_freq' , "Error al guardar tu preferencia");
    })
  }

  _updateSaveStatusMessage(key, message){
    let containerClass;

    if (key === "community_notification_freq") {
      containerClass = "js-community-notifications-save-status"
    } else {
      containerClass = "js-user-notifications-save-status"
    }

    const container = document.querySelector(`.${containerClass}`)
    container.innerHTML = message
    container.classList.remove("hidden")

    setTimeout(function() {
      container.classList.add("hidden")
    }, 3000);
  }

}
