// Rails
require("@rails/ujs").start()
require("turbolinks").start()
import 'controllers'
import 'autocompleter/autocomplete.js'
import axios from "axios";
import 'countdown.js'
import 'mathjax/es5/tex-chtml-full.js';

// CSS
import "stylesheets/application.scss";

// Functions
document.addEventListener("turbolinks:load", function() {
  const inputs = document.querySelectorAll(".js-searcher");
  inputs.forEach((input) => { initAutocomplete(input) })

  // Scroll fix when changing layouts
  window.scrollTo(0, 0);

  // Enable buy button functionality in Course Buy Box (layout V2)
  const buyButton = document.getElementsByClassName('buy-button')
  Array.from(buyButton).forEach(function(button){
    button.addEventListener('click', function(e){
      e.preventDefault();
      const purchase_link = this.getAttribute('data-pricing-course-url');
      document.getElementById('action_with_subscription_form_redirect_to_url').setAttribute("value", purchase_link);
      document.getElementById('action_with_subscription_form_redirect_to_url').parentElement.submit();    
    })
  })

  // Allow reviews pagination and filtering without constant scrolling
  if (window.location.hash) {
    var element = document.getElementById(window.location.hash.substring(1));
    if (element) {
      element.scrollIntoView();
    }
  }
});

function initAutocomplete(input) {
  const autocomplete = require('autocompleter');
  const fetchUrl = input.dataset.url;
  const isDesktop = input.classList.contains('desktop');
  const isMobile = input.classList.contains('mobile');
  const courseId = input.dataset.courseId;
  const onlyGlobal = input.dataset.onlyGlobal === 'true';

  autocomplete({
    input: input,
    debounceWaitMs: 500,
    minLength: 3,
    preventSubmit: false,
    searchMode: onlyGlobal ? 'global' : 'local',
    fetch: function(text, update) {
      // document.querySelector(".js-spinner").classList.remove('hidden');

      let fetchUrlWithQuery = fetchUrl.match(/\?/) ? `${fetchUrl}&q=${text}` : `${fetchUrl}?q=${text}`

      if (this.searchMode === 'local') {
        fetchUrlWithQuery += `&course_id=${courseId}`
      }

      axios.get(fetchUrlWithQuery, {
        headers: { 'Accept': 'application/json' }
      })
      .then((response) => {
        let searchOptions;

        if (onlyGlobal) {
          searchOptions = [];
        } else {
          searchOptions = [
            {type: 'search-options', selected: (this.searchMode == 'local'), search: 'local',  label: 'Buscar en este curso'},
            {type: 'search-options', selected: (this.searchMode == 'global'), search: 'global', label: 'Buscar en todo ingenio'}
          ];
        }

        if (response.data.length > 0) {
          update(searchOptions.concat(response.data));
        } else {
          update(searchOptions.concat([{label: 'Sin resultados', type: 'no-results'}]))
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .then(()=>{
        // document.querySelector(".js-spinner").classList.add('hidden');
      })
    },
    render: function(item, currentValue) {
      const div = document.createElement("div");
      const self = this;
      div.dataset.rank = item.rank;
      div.innerHTML = '';

      // outer div config
      if (item.type === 'search-options') {
        div.classList.add('search-option')
        div.classList.add(item.search)
        if (item.selected) {
          div.classList.add('selected-search')
        } else {
          div.classList.add('not-selected-search')
        }
      }

      // inner span config
      if (item.type === 'no-results') {
        div.innerHTML = `<span class="type">${item.label}</span>`
      } else if (item.type === 'search-options') {
        div.innerHTML = `<span>${item.label}</span>`
      } else {
        if (item.label) { div.innerHTML += `<span class="label">${item.label}</span> ` }

        div.innerHTML += `<span class="type">(${item.type})</span>`

        if (item.summary) { div.innerHTML += `<br/><p class="summary">${item.summary}</p>` }
      }

      return div;
    },
    onSelect: function(item) {
      if (item.type === 'search-options') {
        event.returnValue = true;
        this.searchMode = item.search;
        this.trigger();
      }
      else if (item.value) {
        const text = this.input.value;
        let newLocation = `${item.value}?hltext=${text}&hlid=${item.id}`;

        window.location.href = newLocation;
      }
    },
    customize: function(input, inputRect, container, maxHeight) {
      if (isDesktop) {
        container.style.width = "50vw"
        container.style.top = "51px"
        container.style.right = "16px"
        container.style.left = ""
      }
    },
    trigger: function(){
      let container = document.querySelector(".autocomplete")
      let parent = container.parentNode;
      parent.removeChild(container);

      setTimeout(() => {
        this.input.focus();
        this.input.dispatchEvent(new KeyboardEvent("keydown", { key : "ArrowDown", keyCode : 40 }));
        this.input.dispatchEvent(new KeyboardEvent("keyup", { key : "ArrowDown", keyCode : 40 }));
      }, 100);

      return true
    }
  });
}
