import { Controller } from "stimulus"

// Usage:
//
// <div data-controller="search" data-search-endpoint="/path/to/api">
//   <input type="text" data-target="search.input" data-action="keypress->search#search">
// </div>

export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    this.endpoint = this.data.get("endpoint")
  }

  search({ key }) {
    const errorMsg = document.querySelector(`#${this.inputTarget.id}-error`)

    errorMsg.hidden = true

    if (key === "Enter") {
      if (this.inputTarget.value.length >= 3) {
        const searchParams = new URLSearchParams(new URL(window.location.href).search)
        searchParams.set("q", this.inputTarget.value)

        window.location.href = `${this.endpoint}?${searchParams.toString()}`
      } else {
        errorMsg.hidden = false
      }
    }
  }
}
