import { Controller } from "stimulus"

// Usage:
//
// <div data-controller="users-most-active"
//      data-users-most-active-selected="bg-green-900"
//      data-users-most-active-unselected="text-green-900 bg-white">
//   <button data-action="click->users-most-active#toggle"
//           data-target="trigger.users-most-active"
//           data-type="week">
//     Click
//   </button>
//
//   <div data-target="panel.users-most-active" data-type="week">
//     wadus
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "trigger", "panel" ]

  connect() {
    this.selectedClasses = this.data.get("selected").split(" ")
    this.unselectedClasses = this.data.get("unselected").split(" ")
  }

  toggle({ target }) {
    this.triggerTargets.forEach(e => {
      e.classList.add(...this.unselectedClasses)
      e.classList.remove(...this.selectedClasses)
    })

    target.classList.add(...this.selectedClasses)
    target.classList.remove(...this.unselectedClasses)

    const { type } = target.dataset
    this.panelTargets.forEach(e => {
      e.hidden = !(e.dataset.type === type)
    })
  }
}
