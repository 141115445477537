import { Controller } from "stimulus"

// Usage:
//
// <div data-controller="tooltip"
//      data-tooltip-visible="z-10 opacity-100 pointer-events-auto"
//      data-tooltip-invisible="-z-10 opacity-0 pointer-events-none">
//   <button data-action="mouseenter->tooltip#showTooltip mouseleave->tooltip#hideTooltip">
//     Click
//   </button>
//
//   <div data-target="tooltip.panel" data-action="mouseenter->tooltip#showTooltip mouseleave->tooltip#hideTooltip" hidden>
//     wadus
//   </div>
// </div>

export default class extends Controller {
  static targets = [ "panel" ]

  connect() {
    this.visibleClasses = this.data.get("visible").split(" ")
    this.invisibleClasses = this.data.get("invisible").split(" ")
  }

  showTooltip() {
    if (this.isMobile()) return;
    clearTimeout(this.timeout)
    this.panelTarget.classList.add(...this.visibleClasses)
    this.panelTarget.classList.remove(...this.invisibleClasses)
  }

  hideTooltip() {
    if (this.isMobile()) return;
    this.timeout = setTimeout(() => {
      this.panelTarget.classList.remove(...this.visibleClasses)
      this.panelTarget.classList.add(...this.invisibleClasses)
    }, 250);
  }

  isMobile() {
    return window.innerWidth <= 1024; // lg: tailwind
  }
}
